import React,{useEffect} from 'react'
import Layout from '../Layout'
import {Link} from "react-router-dom"

export default function Dp() {
    
    useEffect(() => {
        // Scroll to the top of the page when the Contact component mounts
        window.scrollTo(0, 0);
    }, []); // Runs only on component mount
  return (
  <Layout>
    <div className='content1'>
   <div class="container" style={{background:"none"}}>
    <h1> Data Protection or Privacy Policy  </h1>
    <ul     style={{ listStyle: "auto", margin: 0, padding: "20px" }}>
    
        <li style={{ padding: "10px" }}>
        Information Collection: We may collect personal information such as name, email address, 
phone number, and job title from users and customers who use our services. We may also 
collect non-personal information such as browser type, operating system, and IP address. We 
collect this information through various means such as website forms, email, and telephone 
communication
        </li>
        <li style={{ padding: "10px" }}>
        Use of Information: We use the collected personal and non-personal information to provide 
and improve our services. We may also use the information to communicate with users and 
customers about our services, to provide technical support, and to enforce our policies. 
        </li>
        <li style={{ padding: "10px" }}>
        Information Sharing: We do not share personal information with third parties unless we have 
obtained explicit consent from the user or customer or unless we are required to do so by law. 
We may share non-personal information with third-party service providers who help us 
improve our services. 
        </li>
        <li style={{ padding: "10px" }}>Data Security: We take reasonable measures to protect personal information from 
unauthorized access, disclosure, or misuse. We use industry-standard encryption and security 
measures to safeguard personal information. We regularly review and update our security 
measures to ensure the safety of personal information. </li>
     <li style={{ padding: "10px" }}>Retention of Information: We retain personal information for as long as necessary to provide 
our services or as required by law. We securely dispose of personal information that is no 
longer needed. </li>
<li style={{ padding: "10px" }}>
user Rights: Users and customers have the right to access, correct, delete, or object to the 
processing of their personal information. They can also withdraw consent at any time. Users 
and customers can exercise these rights by contacting us at [insert contact information].
</li>
<li style={{ padding: "10px" }}> Changes to the Policy: We may update this Data Protection/Privacy Policy from time to time. 
We will notify users and customers of any changes to the policy by posting a notice on our 
website.</li>
        <h4>Conclusion:</h4> <p>LST is committed to protecting the privacy and security of personal information. If you 
have any questions or concerns about our Data Protection/Privacy Policy, please contact us at 
admin@lakshya-soft.com.</p>
    </ul>
   

</div>
</div>


 </Layout>
  )
}

