import React from 'react'
import './Footer.css'
import {Link} from "react-router-dom"

export default function Footer() {
  return (
    <div>
    <footer class="footer-area">
    <img src="./assets/imgs/bg-shape-4.svg" alt="Shape" class="animation-slide-right bg-shape" />
    <div class="footer-top">
        <div class="custom-container">
            <div class="custom-row align-items-end justify-content-between">
                <div class="left-content">
                  
                    <p>We provide the expertise and support to <br></br> propel your business forward.</p>
                    <form action="assets/mail/subscribe.php" method="POST" class="subscribe-form">
                        <div class="subscribe-box d-flex">
                            <input type="email" id="email" name="email" placeholder="Enter Your Email" />
                            <button id="submit2" class="theme-btn">Get Started</button>
                        </div>
                      
                        <div class="input-row">
                            <div class="input-group alert-notification">
                                <div id="alert-message-subscribe" class="alert-msg"></div>
                            </div>
                        </div>
                    </form>
                    
                    <div class="social-icons">
  <a href="https://www.linkedin.com/company/lakshya-software-technologies" target="_blank">
    <i class="bi bi-linkedin"></i>
  </a>
  <a href="https://x.com/LakshyaSoftware?t=U_omGbdaa-M6XiYxEfV24Q&s=09" target="_blank">
  <span class="icon-x">X</span>
  </a>
  <a href="https://www.facebook.com/LakshyaSoftware" target="_blank">
    <i class="bi bi-facebook"></i>
  </a>
  <a href="https://www.instagram.com/lakshyasoftware?igsh=b3B6ZTB2b2k3cHU2" target="_blank">
    <i class="bi bi-instagram"></i>
  </a>
</div>

                </div>

                <div class="right-content pb-5">
                    <div class="right-content-inner">
                        <h2>Let’s get started on something great</h2>
                        <p>Our team of IT experts looks forward to meeting with you <br></br> and providing valuable insights tailored to your business.</p>
                        <li> <Link to="/contact"  class="theme-btn">Get an appointment now</Link></li>
                                              
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom  py-4">
  <div class="container colorvamsi">
    <div class="row">
      
      <div class="col-lg-3 col-md-6 mb-4">
        <h3>Services</h3>
        <ul class="list-unstyled">
        <li><Link to="/bpm">Business Process Management</Link></li>
                                  <li><Link to="/crm">CRM implementation</Link></li>
                                  <li><Link to="/csd">Customized software Development</Link></li>
                                  <li><Link to="/erp">ERP implementation</Link></li>
                                  <li><Link to="/psm">Procurement Supply Management </Link></li>
                                  <li><Link to="/sa">Software Assurance</Link></li>
                                  <li><Link to="/epm">Enterprise performance management</Link></li>
                                  <li><Link to="/hcm">Human capital management</Link></li>
                                  <li><Link to="/cs">Cloud solutions</Link></li>
                                  <li><Link to="/verification">Verification & validation</Link></li>
        </ul>
      </div>
      
      
      <div class="col-lg-3 col-md-6 mb-4">
        <h3>Solutions</h3>
        <ul class="list-unstyled">
        <li><Link to="/es">Embedded Solutions</Link></li>
        <li><Link to="/as">Automative & Smart Solutions </Link></li>
        <li><Link to="/vlsi">Vlsi Engineering & silicon design </Link></li>
        <li><Link to="/systemdesign">System Design Engineering</Link></li>
        <li><Link to="/artificial">Artificial intelligence and advanced analytics</Link></li>
        <li><Link to="/iot">Internet of things </Link></li>
        
                                 
                               
          
        </ul>
      </div>
      

      <div class="col-lg-3 col-md-6 mb-4">
        <h3>Company</h3>
        <ul class="list-unstyled">
        <li>
          <Link to="/aboutus">About Us</Link></li>
                                  <li><Link to="/carrers">Carrers</Link></li>
                                  <li><Link to="/quality">Quality</Link></li>
                                 <li> <Link to="/contact">Contact us</Link></li>
        </ul>
      </div>
      
      
      <div class="col-lg-3 col-md-6 mb-4">
        <h3>Location</h3>
        <div class="mb-3">
          
          
      
        <p>
        <ul class="list-unstyled">
        <li><Link to="/india" className="unclickable-link">India</Link></li>
        <li> <Link to="/usa" className="unclickable-link">USA</Link></li>
        <li><Link to="/uk" className="unclickable-link">UK</Link></li>
        <li> <Link to="/dubai" className="unclickable-link">Dubai</Link></li>
          
          </ul>
          </p>
            
        
        </div>
        <div>
          <h3>Email</h3>
          <p>
            <a href="mailto:Lakshya@mail.com">contact@lakshya-soft.com</a> <br/>
            
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
    

   


    <div class="copyright-area">
        <div class="custom-container">
            <div class="custom-row d-flex align-items-center justify-content-between">
                <ul class="social-links d-flex align-items-center">
                <a href="https://www.linkedin.com/company/lakshya-software-technologies" target="_blank">
    <i class="bi bi-linkedin"></i>
  </a>
  <a href="https://x.com/LakshyaSoftware?t=U_omGbdaa-M6XiYxEfV24Q&s=09" target="_blank">
  <span class="icon-x">X</span>
  </a>
  <a href="https://www.facebook.com/LakshyaSoftware" target="_blank">
    <i class="bi bi-facebook"></i>
  </a>
  <a href="https://www.instagram.com/lakshyasoftware?igsh=b3B6ZTB2b2k3cHU2" target="_blank">
    <i class="bi bi-instagram"></i>
  </a>
  </ul>

                <p>&copy; 2024 All rights reserved by Lakshya Software Technologies</p>
            </div>
        </div>
    </div>
</footer>

</div>
  )
}
