import React,{useEffect} from 'react'
import Layout from '../Layout'
import {Link} from "react-router-dom"

export default function Da() {
    useEffect(() => {
        // Scroll to the top of the page when the Contact component mounts
        window.scrollTo(0, 0);
    }, []); // Runs only on component mount
  return (
  <Layout>
    <div className='content1'>
   <div class="container" style={{background:"none"}}>
    <h1>Data access management and control rules for IT COMPANY </h1>
    <p>
    Lakshya Software Technologies Private Limited (LST) is committed to protecting the 
confidentiality, integrity, and availability of all information assets. This Data Access 
Management and Control Policy outlines our practices for managing and controlling access 
to information assets. 
    </p>

    <h5>SCOPE</h5>
    <p>This policy applies to all employees, contractors, and third-party vendors who have access 
    to LST information assets.</p>
    <h5>Information Classification: </h5>
    <p>All information assets shall be classified based on their sensitivity level. The following are 
    the classification levels:</p>
    <h5>Confidential</h5>
    <p>This level includes information that, if disclosed, could cause severe harm 
    to LST, its employees, or customers. </p>
    <h5>Restricted</h5>
    <p> This level includes information that, if disclosed, could cause moderate harm 
    to LST, its employees, or customers. </p>
    <h5>Public</h5>
    <p> This level includes information that can be shared with the public without causing 
    harm to LST, its employees, or customers. </p>
    <h5>Access Control:</h5>
    <p>Access to information assets shall be restricted to only authorized personnel. Access control 
shall be implemented using a combination of user authentication, authorization, and 
auditing.</p>
<h5>User Authentication: </h5>
<p>All users shall be required to authenticate themselves using a unique identifier such as a 
username and password, biometric, or smart card before accessing any information asset. </p>
<h5>Authorization: </h5>
<p>Access to information assets shall be granted based on the user's job function and need-to
know principle. Authorization shall be granted by a designated authority such as the system 
administrator or data owner. </p>
<h5>Auditing: </h5>
<p>All access to information assets shall be logged, and audit trails shall be maintained. Audit 
logs shall include user identification, date and time of access, and actions performed on the 
asset.
 </p>
<h5>Least Privilege:  </h5>
<p>Users shall be granted the minimum level of access necessary to perform their job function. 
This principle ensures that users do not have unnecessary access to information assets that 
they do not require to perform their duties.. </p>
<h5>Separation of Duties:  </h5>
<p>Users shall be assigned roles and responsibilities that are separate and independent of each 
other. This principle ensures that no single user has complete control over an information 
asset or system. </p>
<h5>Compliance: </h5>
<p>LST shall comply with all applicable laws, regulations, and industry standards related to data 
access management and control. </p>
<h5>Training and Awareness: </h5>
<p>All employees, contractors, and third-party vendors shall receive regular training and 
awareness on data access management and control policies, procedures, and best practices.</p>
<h5>Conclusion: </h5>
<p>LST is committed to maintaining the confidentiality, integrity, and availability of information 
assets through effective data access management and control. If you have any questions or 
concerns about our Data Access Management and Control Policy, please contact us 
at admin@lakshya-soft.com.</p>

</div>
</div>


 </Layout>
  )
}
