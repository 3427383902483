import React, { useEffect } from 'react'
import Layout from '../Layout'
import { Link } from "react-router-dom"

export default function Rt() {
    useEffect(() => {
        // Scroll to the top of the page when the Contact component mounts
        window.scrollTo(0, 0);
    }, []); // Runs only on component mount
    return (
        <Layout>
            <div className='content1'>
                <div class="container" style={{ background: "none" }}>
                    <h1> Regular training of employees on Data Protection  </h1>
                    <ul style={{ listStyle: "none", margin: 0, padding: "20px" }}>
                        <li>
                            <p> Lakshya Software Technologies Private Limited (LST) is committed to protecting the
                                confidentiality, integrity, and availability of all information assets, including personal data.
                                This policy outlines our practices for providing regular training to employees on data
                                protection to ensure the proper handling of personal data.  </p>
                        </li>
                        <li>
                            <h5>Scope:</h5>
                            <p>this policy applies to all employees who have access to personal data processed by LST.   </p>
                        </li>
                        <li>
                            <h5>Training Requirements: </h5>
                            <p>All employees shall receive regular training on data protection, including the following topics:
                                Overview of data protection laws and regulations applicable to LST
                                Importance of protecting personal data
                                Personal data handling policies and procedures
                                Data breach response procedures
                                Reporting obligations
                                Best practices for safeguarding personal data</p>
                        </li>
                        <li>
                            <h5>Frequency of Training:  </h5>
                           <p> All employees shall receive data protection training upon joining LST and at least once every
                            year thereafter. Additional training may be provided if there are significant changes to data
                            protection laws, regulations, or policies.</p>
                        </li>
                        <li>
                            <h5>Training Methodology:   </h5>
                           <p> Data protection training may be delivered through various methods, including in-person
                            training, online training, webinars, or self-paced training modules. The training materials shall
                            be designed to be easily understandable by all employees.</p> </li>
                        <li>
                            <h5>Documentation and Recordkeeping:  </h5>
                           <p> Documentation of data protection training shall be maintained for each employee, including
                            the date and type of training received. Training records shall be kept for a minimum of [2
                            years] and shall be made available to management, auditors, or regulatory authorities upon
                            request. </p> </li>
                        <li>
                            <h5>Compliance:   </h5>
                          <p>  LST shall comply with all applicable laws, regulations, and industry standards related to
                            providing regular training to employees on data protection.</p></li>
                        <li>
                            <h5>  Evaluation and Improvement:</h5>
                           <p>The effectiveness of data protection training shall be evaluated regularly, and improvements
                            shall be made as necessary to ensure that employees are equipped with the knowledge and
                            skills necessary to protect personal data.</p>   </li>

                        <h5>Conclusion:   </h5>
                       <p>LST is committed to maintaining the confidentiality, integrity, and availability of personal data
                        through regular training of employees on data protection. If you have any questions or
                        concerns about our Data Protection Training Policy, please contact us at admin@lakshya
                        soft.com.</p>








                    </ul>


                </div>
            </div>

        </Layout>
    )
}
