import React,{useEffect} from 'react'
import Layout from '../Layout'
import {Link} from "react-router-dom"

export default function Is() {
    useEffect(() => {
        // Scroll to the top of the page when the Contact component mounts
        window.scrollTo(0, 0);
    }, []); // Runs only on component mount
  return (
  <Layout>
    <div className='content1'>
   <div class="container" style={{background:"none"}}>
    <h1> Information Security Policy for IT COMPANY  </h1>
    <ul     style={{ listStyle: "auto", margin: 0, padding: "20px" }}>
    
        <li style={{ padding: "10px" }}>
       <h5> Lakshya Software Technologies Private Limited (LST)</h5> is committed to protecting the confidentiality, 
integrity, and availability of all information assets. This Information Security Policy outlines our 
practices for protecting information assets against unauthorized access, use, disclosure, and 
destruction.
        </li>
        <li style={{ padding: "10px" }}>
        <h5>Scope: </h5>
        This policy applies to all employees, contractors, and third-party vendors who have access to LST 
information assets. 
        </li>
        <li style={{ padding: "10px" }}>
        <h5>Information Classification: </h5>
        All information assets shall be classified based on their sensitivity level. The following are the 
classification levels: 
        </li>
        <li style={{ padding: "10px" }}>
            <h5>Confidential: </h5> 
            This level includes information that, if disclosed, could cause severe harm to LST, its 
employees, or customers.  </li>
     <li style={{ padding: "10px" }}><h5>Restricted:</h5> 
     This level includes information that, if disclosed, could cause moderate harm to LST, its 
     employees, or customers. </li>
<li style={{ padding: "10px" }}>
<h5>Public</h5> This level includes information that can be shared with the public without causing harm to 
LST, its employees, or customers.
</li>
<li style={{ padding: "10px" }}>
    <h5>Access Control: </h5>
    Access to information assets shall be restricted to only authorized personnel. Access control shall be 
implemented using a combination of user authentication, authorization, and auditing.</li>
<li style={{ padding: "10px" }}>
    <h5>Physical Security: : </h5>
    Physical access to LST premises and equipment shall be restricted to only authorized personnel. 
Physical security measures such as biometric access control, security cameras, and security guards 
shall be implemented to protect physical assets. </li>
<li style={{ padding: "10px" }}>
    <h5>Network Security: </h5>
    Network security measures such as firewalls, intrusion detection and prevention systems, and 
antivirus software shall be implemented to protect the network from unauthorized access and 
malicious activities.</li>
<li style={{ padding: "10px" }}>
    <h5>Data Protection:  </h5>
    All confidential and restricted information shall be protected using encryption and other appropriate 
security measures. Backup and recovery procedures shall be implemented to ensure the availability 
of information assets.</li>
<li style={{ padding: "10px" }}>
    <h5>Incident Management:   </h5>
    An incident management plan shall be developed and maintained to manage security incidents such 
as data breaches, malware attacks, and physical security breaches. The plan shall include procedures 
for incident reporting, assessment, containment, and recovery..</li>
<li style={{ padding: "10px" }}>
    <h5>Compliance: </h5>
    .LST shall comply with all applicable laws, regulations, and industry standards related to information 
    security.</li>
    <li style={{ padding: "10px" }}>
    <h5>Compliance: </h5>
    .All employees, contractors, and third-party vendors shall receive regular training and awareness on 
    information security policies, procedures, and best practices.</li>
    <h5>Conclusion:  </h5>
   <p> LST is committed to maintaining a secure and safe environment for all information assets. If you 
have any questions or concerns about our Information Security Policy, please contact us at 
admin@lakshya-soft.com.</p>
    
    
    

       

    </ul>
   

</div>
</div>


 </Layout>
  )
}





