import React,{useEffect} from 'react'
import Layout from '../Layout'
import {Link} from "react-router-dom"

export default function Pd() {
    useEffect(() => {
        // Scroll to the top of the page when the Contact component mounts
        window.scrollTo(0, 0);
    }, []); // Runs only on component mount
  return (
  <Layout>
    <div className='content1'>
   <div class="container" style={{background:"none"}}>
    <h1> Personal Data  for IT COMPANY   </h1>
    <ul     style={{ listStyle: "auto", margin: 0, padding: "20px" }}>
    
        <li style={{ padding: "10px" }}>
       <p> Lakshya Software Technologies Private Limited (LST) is committed to protecting the 
confidentiality, integrity, and availability of personal data. This policy outlines our practices 
for encrypting personal data to ensure its protection against unauthorized access, use, 
disclosure, and destruction. </p>
        </li>
        <li style={{ padding: "10px" }}>
        <h5>Scope: </h5>
        <p>This policy applies to all employees, contractors, and third-party vendors who have access to 
personal data processed by LST.  </p>
        </li>
        <li style={{ padding: "10px" }}>
        <h5>Encryption Standards:  </h5>
        All personal data shall be encrypted during storage and transmission using industry-standard 
encryption algorithms such as Advanced Encryption Standard (AES) or Transport Layer 
Security (TLS).
        </li>
        <li style={{ padding: "10px" }}>
            <h5>Encryption of Personal Data in Storage: </h5> 
            All personal data stored in databases, file systems, or any other storage media shall be 
encrypted using strong encryption algorithms. Encryption keys shall be managed securely, 
and access to the keys shall be restricted to authorized personnel only.   </li>
     <li style={{ padding: "10px" }}><h5>Encryption of Personal Data in Transit: </h5> 
     All personal data transmitted over public networks or the internet shall be encrypted using 
Transport Layer Security (TLS) or Secure Sockets Layer (SSL). Encryption certificates shall be 
validated, and connections using weak or outdated encryption protocols shall be disabled. </li>
<li style={{ padding: "10px" }}>
<h5>Encryption of Personal Data on Portable Devices: </h5> 
All personal data stored on portable devices such as laptops, smartphones, and tablets shall 
be encrypted using strong encryption algorithms. Encryption keys shall be managed securely, 
and access to the keys shall be restricted to authorized personnel only. 
</li>
<li style={{ padding: "10px" }}>
    <h5>Compliance:  </h5>
    LST shall comply with all applicable laws, regulations, and industry standards related to 
    encryption of personal data. </li>
<li style={{ padding: "10px" }}>
    <h5>Training and Awareness: </h5>
    All employees, contractors, and third-party vendors shall receive regular training and 
    awareness on the importance of encryption and its proper use.  </li>
<li style={{ padding: "10px" }}>
    <h5>Incident Management:  </h5>
    In the event of a breach or loss of encrypted personal data, an incident management plan 
shall be activated to contain the breach, assess the impact, and notify affected individuals and 
authorities as required by law..</li>
<li style={{ padding: "10px" }}>
    <h5>Data Protection:  </h5>
    All confidential and restricted information shall be protected using encryption and other appropriate 
security measures. Backup and recovery procedures shall be implemented to ensure the availability 
of information assets.</li>

    <h5>Conclusion:   </h5>
    An incident management plan shall be developed and maintained to manage security incidents such 
as data breaches, malware attacks, and physical security breaches. The plan shall include procedures 
for incident reporting, assessment, containment, and recovery.

  
 
    
    
    

       

    </ul>
   

</div>
</div>


 </Layout>
  )
}





