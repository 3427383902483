import React, { useEffect } from 'react';
import Layout from './Layout';
import './contact.css';

export default function Contact() {
    useEffect(() => {
        // Scroll to the top of the page when the Contact component mounts
        window.scrollTo(0, 0);
    }, []); // Runs only on component mount

    return (
        <Layout>
            <section className="contact-area content1">
                <div className="custom-container">
                    <div className="custom-row">
                        <div className="contact-form-wrap">
                            <div className="contact-form-body">
                                <h5 className="section-subtitle">Contact</h5>
                                <h1 className="section-title">Let's get in touch</h1>
                                <p>
                                    You can reach us anytime via{' '}
                                    <a href="mailto:contact@lakshya-soft.com">contact@lakshya-soft.com</a>
                                </p>
                                <form action="assets/mail/contact.php" method="POST" className="contact-form">
                                    <div className="input-row">
                                        <div className="input-group">
                                            <label htmlFor="first_name">First Name</label>
                                            <input id="first_name" type="text" name="first_name" placeholder="First Name" />
                                        </div>
                                        <div className="input-group">
                                            <label htmlFor="last_name">Last Name</label>
                                            <input id="last_name" type="text" name="last_name" placeholder="Last Name" />
                                        </div>
                                    </div>
                                    <div className="input-row">
                                        <div className="input-group">
                                            <label htmlFor="email">Email</label>
                                            <input type="email" id="email" name="email" placeholder="Your Email" />
                                        </div>
                                    </div>
                                    <div className="input-row">
                                        <div className="input-group phone-number">
                                            <select className="number-prefix">
                                                <option value="in">IN</option>
                                                <option value="uk">UK</option>
                                                <option value="us">US</option>
                                            </select>
                                            <label htmlFor="phone_number">Phone Number</label>
                                            <input
                                                id="phone_number"
                                                type="text"
                                                name="phone_number"
                                                placeholder="Your Number"
                                            />
                                        </div>
                                        <div className="input-group">
                                            <label htmlFor="country">Country</label>
                                            <input type="text" id="homeland" name="country" placeholder="Your Country" />
                                        </div>
                                    </div>
                                    <div className="input-row">
                                        <div className="input-group">
                                            <label htmlFor="message">Message</label>
                                            <textarea name="message" id="message" placeholder="Leave us a message...."></textarea>
                                        </div>
                                    </div>
                                    <div className="input-row">
                                        <div className="input-group input-checkbox">
                                            <input type="checkbox" id="privacy_policy_accept" />
                                            <label htmlFor="privacy_policy_accept">
                                                You agree to our <a href="#">terms and conditions.</a>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="input-row">
                                        <div className="input-group">
                                            <button id="submit" className="theme-btn">
                                                Get Started
                                            </button>
                                        </div>
                                    </div>
                                    <div className="input-row">
                                        <div className="input-group alert-notification">
                                            <div id="alert-message" className="alert-msg"></div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className="contact-experience">
                            <ul>
                                <li>
                                    <h1>
                                        9+ <span>Years</span>
                                    </h1>
                                    <p>Field Experience</p>
                                </li>
                                <li>
                                    <h1>
                                        350+ <span>Projects</span>
                                    </h1>
                                    <p>Done Around the World</p>
                                </li>
                                <li>
                                    <h1>99%</h1>
                                    <p>Client Satisfaction</p>
                                </li>
                                <li>
                                    <h1>
                                        2015<span>Year</span>
                                    </h1>
                                    <p>Established On</p>
                                </li>
                                <li>
                                    <h1>
                                        2 <span>Mins</span>
                                    </h1>
                                    <p>Response Time</p>
                                </li>
                            </ul>
                        </div>

                        <div className="contact-infos">
                            <div className="contact-infos-inner">
                                <div className="contact-info">
                                <img src="./assets/imgs/support-icon.svg" alt="Support"/>
                                    <h3>Contact Info</h3>
                                    <p>
                                        +91-8143233244<br />
                                        contact@lakshya-soft.com
                                    </p>
                                </div>
                                <div className="contact-office-info contact-info">
                                    <img src="./assets/imgs/map-icon.svg" alt="Map" />
                                    {/* <h3>Visit our office</h3> */}
                                    <p>
                                    Lakshya Software Technologies Private Limited,3rd Floor Plot No.14, Rohini Layout Road, 
Landmark: Behind Mehfil Restaurant Road . 
Madhapur, Hyderabad, Telangana 500081 .</p>
                                </div>

                                <ul className="contact-social-links">
                                    <li>
                                        <a href="#">
                                            <i className="iconoir-facebook"></i> facebook
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i className="iconoir-twitter"></i> Twitter
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i className="iconoir-instagram"></i> Instagram
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i className="iconoir-linkedin"></i> LinkedIn
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <section>
                    <div className="custom-container mt-5">
                    <div className="row d-flex align-items-stretch">
    <div className="col-lg-3">
        <div className="bgcolor text-center p-4 h-100">
            <img src="./assets/imgs/map-icon.svg" alt="Map" />
            <p className="text-center">
                Vijayawada Office:
                <br />
                Lakshya Software Technologies Pvt. Ltd.,
                <br />
                D.No : 60, GEV Radha Madhav, Comm Unit. 4, 5th Floor, 9-10, Polyclinic Rd, Siddhartha Nagar, Vijayawada, Andhra Pradesh 520010
            </p>
        </div>
    </div>
    <div className="col-lg-3">
        <div className="bgcolor text-center p-4 h-100">
            <img src="./assets/imgs/map-icon.svg" alt="Map" />
            <p className="text-center">
                USA Office:
                <br />
                Lakshya Software Technologies LLC.
                <br />
                39111 6 mile rd Ste 503, Livonia, MI 48152
                <br />
                connectusa@lakshya-soft.com
            </p>
        </div>
    </div>
    <div className="col-lg-3">
        <div className="bgcolor text-center p-4 h-100">
            <img src="./assets/imgs/map-icon.svg" alt="Map" />
            <p className="text-center">
                UAE Office:
                <br />
                Lakshya Software Technologies
                <br />
                79A Street Villa no : 12, Al Satwa - Dubai
                <br />
                connectuae@lakshya-soft.com
                <br />
                +975 0563976434
            </p>
        </div>
    </div>
    <div className="col-lg-3">
        <div className="bgcolor text-center p-4 h-100">
            <img src="./assets/imgs/map-icon.svg" alt="Map" />
            <p className="text-center">
                UK Office:
                <br />
                Lakshya Software Technologies
                <br />
                Brentwood London U.K
                <br />
                +44-7342394160 (UK)
            </p>
        </div>
    </div>
    <div>
    <iframe 
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.172777568503!2d78.37936257369098!3d17.451442600959638!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb917b59182915%3A0xdebd743469652e1!2sLakshya%20Software%20Technologies%20Private%20Limited!5e0!3m2!1sen!2sin!4v1740465379755!5m2!1sen!2sin" 
    width="600" 
    height="450" 
    style={{ border: 0, width: "100%", height: "450px" }} 
    allowfullscreen 
    loading="lazy" 
    referrerpolicy="no-referrer-when-downgrade">
</iframe>

    </div>
</div>

                    </div>
                </section>
            </section>
        </Layout>
    );
}
