import React, { useEffect } from 'react';
import Layout from './Layout'
import './carrers.css'
import {Link}from "react-router-dom"
import './Quality.css'


export default function Quality() {
    useEffect(() => {
        // Scroll to the top of the page when the Contact component mounts
        window.scrollTo(0, 0);
    }, []); // Runs only on component mount
  return (
  <Layout>
    <div className='content1'>
        <div class="container">
      
    <section class="quality-section ">
        <h1 class="heading">Our Commitment to Excellence</h1>
        <p>At <span class="highlight">Lakshya Software Technologies</span>, we are dedicated to delivering excellence in every aspect of our operations.</p>
        <p>Our quality framework is backed by <span class="highlight">
            globally recognized certifications</span> that ensure we meet the highest 
            industry standards.</p>
      
       
    </section>
    </div>
    

    <div class="container mt-4">
        <div class="certification">
            <img src="./assets/imgs/clients/iso.png" alt="ISO 9001"/>
            <div class="certification-content">
                <div class="certification-title">ISO 9001:2015 – Quality Management Systems</div>
                <div class="certification-description">Consistent delivery of high-quality services aligned with customer expectations and regulatory requirements.</div>
            </div>
        </div>
        <div class="certification">
            <img src="./assets/imgs/clients/iso2022.png" alt="ISO 27001"/>
            <div class="certification-content">
                <div class="certification-title">ISO/IEC 27001:2022 – Information Security Management</div>
                <div class="certification-description">Robust protocols for safeguarding client data and assets from threats.</div>
            </div>
        </div>
        <div class="certification">
            <img src="./assets/imgs/clients/iso2018.png" alt="ISO 45001"/>
            <div class="certification-content">
                <div class="certification-title">ISO 45001:2018 – Occupational Health and Safety Management</div>
                <div class="certification-description">Maintaining safe and productive workplaces for employees and partners.</div>
            </div>
        </div>
        <div class="certification">
            <img src="./assets/imgs/clients/iso.png" alt="TC-260"/>
            <div class="certification-content">
                <div class="certification-title">TC-260 – Talent Competency Certification</div>
                <div class="certification-description">Ensuring workforce excellence and consistent delivery in every project.</div>
            </div>
        </div>
    </div>


        
      


      
       
        
        {/* <section class="about-service3-area">
            <div class="custom-container">
                <div class="custom-row align-items-center">
                    <div class="img-box">
                        <img src="./assets/imgs/about-service-3.png" alt="About" />
                    </div>

                    <div class="content-box">
                        <h1>Why our services are 
                            better than others?</h1>
                        <p>At Lakshya Software Technologies, we believe our careers are uniquely positioned to stand out in the competitive tech landscape due to our distinct approach and values. Here are several key differentiators that make careers 
                            at Lakshya not only rewarding but also transformative for our team members:</p>
                            <p>Our culture emphasizes innovation and creativity. We encourage our employees to think outside the box and take ownership of their projects. This empowerment fosters a dynamic environment where new ideas are welcomed,
                                 leading to groundbreaking solutions that set us apart in the industry.</p>
                        <ul>
                            <li>
                                <i class="las la-check"></i> PPD Development
                            </li>
                            <li>
                                <i class="las la-check"></i> Quick Response
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section> */}


        
      
        
        {/* <section class="contact-area">
            <div class="custom-container">
                <div class="custom-row">
                    <div class="contact-form-wrap">
                        <div class="contact-form-body">
                            <h5 class="section-subtitle">Contact</h5>
                            <h1 class="section-title">Lets get in touch</h1>
                            <p>You can reach us anytime via <a href="mailto: contact@lakshya-soft.com"> contact@lakshya-soft.com</a></p>
                            <form action="assets/mail/contact.php" method="POST" class="contact-form">
                                <div class="input-row">
                                    <div class="input-group">
                                        <label for="first_name">First Name</label>
                                        <input id="first_name" type="text" name="first_name" placeholder="First Name" />
                                    </div>
                                    <div class="input-group">
                                        <label for="last_name">Last Name</label>
                                        <input id="last_name" type="text" name="last_name" placeholder="Last Name" />
                                    </div>
                                </div>
                                <div class="input-row">
                                    <div class="input-group">
                                        <label for="email">Email</label>
                                        <input type="email" id="email" name="email" placeholder="Your Email" />
                                    </div>
                                </div>
                                <div class="input-row">
                                    <div class="input-group phone-number">
                                        <select class="number-prefix">
                                            <option value="uk">IND</option>
                                        </select>
                                        <label for="phone_number">Phone Number</label>
                                        <input id="phone_number" type="text" name="phone_number" placeholder="Your Number" />
                                    </div>
                                    <div class="input-group">
                                        <label for="country">Country</label>
                                        <input type="text" id="homeland" name="country" placeholder="Your Country" />
                                    </div>
                                </div>
                                <div class="input-row">
                                    <div class="input-group">
                                        <label for="message">Message</label>
                                        <textarea name="message" id="message" placeholder="Leave us a message...."></textarea>
                                    </div>
                                </div>
                                <div class="input-row">
                                    <div class="input-group input-checkbox">
                                        <input type="checkbox" id="privacy_policy_accept"/>
                                        <label for="privacy_policy_accept">You agree to our <a href="#">terms and conditions.</a></label>
                                    </div>
                                </div>

                                <div class="input-row">
                                    <div class="input-group">
                                        <button id="submit" class="theme-btn">Get Started</button>
                                    </div>
                                </div>
                              
                                <div class="input-row">
                                    <div class="input-group alert-notification">
                                        <div id="alert-message" class="alert-msg"></div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div class="contact-experience">
                        <ul>
                            <li>
                                <h1>9+ <span>Years</span></h1>
                                <p>Field Experience </p>
                            </li>
                            <li>
                                <h1>350+ <span>Projects</span></h1>
                                <p>Done Around World </p>
                            </li>
                            <li>
                                <h1>99%</h1>
                                <p>Client Satisfaction</p>
                            </li>
                            <li>
                                <h1>2015 <span>Year</span></h1>
                                <p>Established On</p>
                            </li>
                            <li>
                                <h1>2 <span>Mins</span></h1>
                                <p>Response Time</p>
                            </li>
                        </ul>
                    </div>

                    <div class="contact-infos">
                        <div class="contact-infos-inner">
                            <div class="contact-info">
                                <img src="./assets/imgs/support-icon.svg" alt="Support" />
                                <h3>Contact Info</h3>
                                <p>
                                8143233244 <br/>
                                contact@lakshya-soft.com
                                </p>
                            </div>
                            <div class="contact-office-info contact-info">
                                <img src="./assets/imgs/map-icon.svg" alt="Map" />
                                <h3>Visit our office</h3>
                                <p>Lakshya Software Technologies Private Limited, Dno 1-89/A/7, Plot No 7, 3rd Floor, Trendz Axis, Hyderabad, Madhapur, Serilingampally, Rangareddy,  Telangana, 500081.</p>
                            </div>

                            <ul class="contact-social-links">
                                <li>
                                    <a href="#">
                                        <i class="iconoir-facebook"></i> facebook
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="iconoir-twitter"></i> Twitter
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="iconoir-instagram"></i> Instagram
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="iconoir-linkedin"></i> linkedin
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
    </div>
   </Layout>
  )
}
